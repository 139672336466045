<template>
    <div class="main-container">
        <img :src="require('../assets/bg2.jpg')" class="bg">
        <div id="loading" v-if="showLoading">
            <div class="content">
        
                <v-icon name="spinner" pulse scale="3" />

            </div>
        </div>
        <div class="d-flex flex-wrap wrapper">
            <div class="sidebar" v-if="sideBar">
                <div class="align-items-end justify-content-end text-right text-white">
                    <h4 style="cursor:pointer;" @click="openSidebar">x</h4>
                </div>
                <div class="text-center">
                    <img :src="require('../assets/logo.png')" style="margin-bottom:40px;" class="mx-auto text-center">
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'main'}" @click="exitModal">
                    <img :src="require('../assets/icon-dash.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Dashboard</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="profile">
                    <img :src="require('../assets/profile.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Profile</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" @click="transactions">
                    <img :src="require('../assets/transactions.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Transactions</h4>
                </div>
                <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'changePin'}" @click="changePin">
                    <img :src="require('../assets/change pipn.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Change Pin</h4>
                </div>
                <div style="position:relative;">
                    <div class="sidebar-link d-flex flex-wrap" :class="{active: modalState == 'privateKey'}" @click="privateKey">
                        <img :src="require('../assets/privatekey.png')" alt="" class="sidebar-link-img">
                        <h4 class="sidebar-link-text">Get Private Key</h4>
                        <img :src="require('../assets/donw white.png')" alt="" class="ml-2 arrow-down">
                    </div>
                    <div class="key-choices" v-if="modalState == 'privateKey'">
                        <div class="choice" @click="viewPrivateKey">
                            <h4>View</h4>
                        </div>
                        <div class="choice" @click="importPrivateKey">
                            <h4>Import</h4>
                        </div>
                    </div>
                </div>
                <div class="sidebar-link d-flex flex-wrap">
                    <img :src="require('../assets/support.png')" alt="" class="sidebar-link-img">
                    <h4 class="sidebar-link-text">Support</h4>
                </div>
            </div>
            <div class="main-body">
                <div class="topbar d-flex flex-wrap justify-content-between mb-5">
                    <div class="d-flex justify-content-between">
                        <button class="sidebar-toggler text-white my-auto mr-3" type="button" @click="openSidebar">
                            <v-icon name="chevron-right" />
                        </button>
                        <input class="form-control mr-sm-2 search-bar my-auto" type="search" placeholder="Search transaction" aria-label="Search">
                    </div>
                    <div class="left-section d-flex flex-wrap justify-content-center align-items-center text-white">
                        <h6 class="my-auto" style="margin-right:3em;cursor:pointer;" @click="logout">Logout</h6>
                        <img :src="require('../assets/notif.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <img :src="require('../assets/settings.png')" alt="" class="topbar-img my-auto" style="margin-right:3em;">
                        <div class="profile my-auto">
                            <h5 class="profile-name">{{ init.profile.fullName }}</h5>
                            <h6 class="profile-email">{{ init.profile.emailAddress }}</h6>
                        </div>
                    </div>
                </div>
                <div class="tabs d-flex flex-wrap justify-content-around mb-3">
                    <div class="tab" :class="{active: state == 'main-wallet'}" @click="changeState('main-wallet')">
                        <h6>MAIN WALLET</h6>
                        <div class="active-circle mx-auto" v-if="state == 'main-wallet'"></div>
                    </div>
                    <div class="tab" :class="{active: state == 'tokens'}" @click="changeState('tokens')">
                        <h6>MY TOKENS</h6>
                        <div class="active-circle mx-auto" v-if="state == 'tokens'"></div>
                    </div>
                    <div class="tab" :class="{active: state == 'nft'}" @click="changeState('nft')">
                        <h6>MY NFTS</h6>
                        <div class="active-circle mx-auto" v-if="state == 'nft'"></div>
                    </div>
                    <div class="tab" :class="{active: state == 'subtokens'}" @click="changeState('subtokens')">
                        <h6>SUBWALLET TOKENS</h6>
                        <div class="active-circle mx-auto" v-if="state == 'subtokens'"></div>
                    </div>
                    <div class="tab" :class="{active: state == 'subnft'}" @click="changeState('subnft')">
                        <h6>SUBWALLET NFTS</h6>
                        <div class="active-circle mx-auto" v-if="state == 'subnft'"></div>
                    </div>
                </div>
                <div class="main-wallet" v-if="state == 'main-wallet'">
                    <div class="ceza-wallet d-flex flex-wrap justify-content-between align-items-center">
                        <div class="wallet-info my-auto">
                            <h4 class="address">{{ init.address.addressValue }}</h4>
                            <h6 class="balance-label">Wallet Balance</h6>
                            <h2 class="balance">CEZA {{ formatAmount(init.fund.balance) }}</h2>
                            <div class="d-flex flex-wrap justify-content-between">
                                <img :src="require('../assets/button-send.png')" alt="" class="wallet-button my-auto" @click="send">
                                <img :src="require('../assets/button-receive.png')" alt="" class="wallet-button my-auto" @click="receive">
                                <img :src="require('../assets/button-exchange.png')" alt="" class="wallet-button my-auto" @click="exchange">
                            </div>
                        </div>
                        <img :src="require('../assets/3d logo.png')" alt="" class="ceza-img my-auto ml-5">
                    </div>
                    <div class="other-wallet d-flex flex-wrap justify-content-around mt-5">
                        <div class="cbt-wallet d-flex flex-wrap justify-content-between">
                            <img :src="require('../assets/cbt token.png')" alt="" class="wallet-img my-auto">
                            <div class="wallet-info my-auto">
                                <h6 class="balance-label">Wallet Balance</h6>
                                <h2 class="balance">{{ formatAmount(cbtFund.balance) }}</h2>
                            </div>
                        </div>
                        <div class="dao-wallet d-flex flex-wrap justify-content-between">
                            <img :src="require('../assets/dao token.png')" alt="" class="wallet-img my-auto">
                            <div class="wallet-info my-auto">
                                <h6 class="balance-label">Wallet Balance</h6>
                                <h2 class="balance">{{ formatAmount(daoFund.balance) }}</h2>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="exchange text-white mt-5">
                        <h4>Exchange</h4>
                        <div class="form d-flex flex-wrap justify-content-around mt-3">
                            <div class="input-group">
                                <input type="text" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2">BLX</span>
                                </div>
                            </div>
                            <img :src="require('../assets/exchange  dashboard 2.png')" alt="" class="my-auto">
                            <div class="input-group">
                                <input type="text" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text" id="basic-addon2">ETH</span>
                                </div>
                            </div>
                            <button class="btn btn-main mt-4">Connect Wallet</button>
                        </div>
                    </div> -->
                    <div class="history mt-5 d-flex flex-wrap justify-content-around">
                        <img :src="require('../assets/qr.png')" alt="" class="my-auto" style="width:20em;"> 
                        <div class="transactions text-white">
                            <h4>Transactions</h4>
                            <div class="transaction d-flex flex-wrap justify-content-between mb-3" v-for="h in init.fund.history" :key="h.hash">
                                <img v-if="h.confirmations >= 2" :src="require('../assets/green.png')" alt="" class="dot my-auto">
                                <img v-else :src="require('../assets/red.png')" alt="" class="dot my-auto">
                                <div class="transaction-info">
                                    <h6>{{ formatDate(h.input.timeStamp) }}</h6>
                                    <h6> <div class="hash"><a :href="'https://explorer.blx.org/#/transaction/' + h.hash" target="_blank"> {{ h.hash }}</a> </div></h6>
                                    <h6>{{  formatAmount(h.amount) }}</h6>
                                </div>
                                <img :src="require('../assets/txn line.png')" alt="">
                            </div>
                            <h6 v-if="init.fund.history.length == 0">No transactions found.</h6>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state == 'tokens'">
                    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('token')">
                    <v-icon name="plus" scale="3" class="plus my-auto"/>
                    </b-card>
                    <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="!asset.nft && asset.active" :asset="asset" :userAddress="init.address.addressValue" @view="viewCard(asset.symbol, asset.nft)" @send="sendAsset(asset)"/>
                </div>

                <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state == 'nft'">
                    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('nft')">
                    <v-icon name="plus" scale="3" class="plus my-auto"/>
                    </b-card>
                    <AssetCard v-for="asset in assetInfo" :key="asset.id" v-if="asset.nft && asset.active" :asset="asset" :userAddress="init.address.addressValue" @view="viewCard(asset.symbol, asset.nft)" @send="sendAsset(asset)"/>
                </div>

                <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state == 'subtokens'">
                    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('token')">
                    <v-icon name="plus" scale="3" class="plus my-auto"/>
                    </b-card>
                    <SubCard v-for="asset in subwalletInfo" :key="asset.id" v-if="!asset.nft && asset.symbol != 'BLX'" :asset="asset" @view="viewSubCard(asset.symbol, asset.nft)" @send="sendSubAsset(asset)"/>
                </div>

                <div class="d-flex flex-wrap container-fluid body-container justify-content-center" v-if="state == 'subnft'">
                    <b-card style="width: 20rem; height:25rem;" class="m-5 container p-2 text-center" @click="initContract('nft')">
                    <v-icon name="plus" scale="3" class="plus my-auto"/>
                    </b-card>
                    <SubCard v-for="asset in subwalletInfo" :key="asset.id" v-if="asset.nft && asset.symbol != 'BLX'" :asset="asset" @view="viewSubCard(asset.symbol, asset.nft)" @send="sendSubAsset(asset)"/>
                </div>
            </div>
        </div>
        <PrivateKey />
        <ImportPrivateKey />
        <ChangePin @close="exitModal"/>
        <TokenContract v-if="ifAddingAsset" @close="closeContract" :isNft="isNft"/>
        <SendAsset :info="selectedAsset"/>
        <SendSubAsset :info="selectedAsset"/>
        <Send />
        <Deposit />
        <Exchange />
        <ConfirmLogout />
    </div>
</template>

<script>
import PrivateKey from '@/components/modal/PINPrivateKey.vue'
import ImportPrivateKey from '@/components/modal/ImportPrivateKey.vue'
import ChangePin from '@/components/modal/ChangePin.vue'
import Send from '@/components/modal/Send.vue'
import Deposit from '@/components/modal/Deposit.vue'
import TokenContract from '@/components/modal/TokenContract.vue'
import AssetCard from '@/components/card/AssetCard.vue'
import SubCard from '@/components/card/SubWalletCard.vue'
import SendAsset from '@/components/modal/SendMintedAsset.vue'
import SendSubAsset from '@/components/modal/SendSubAsset.vue'
import Exchange from '@/components/modal/Exchange.vue'
import ConfirmLogout from '@/components/modal/ConfirmLogout.vue'
export default {
    components: {
        PrivateKey,
        ImportPrivateKey,
        ChangePin,
        Send,
        Deposit,
        SendAsset,
        AssetCard,
        SubCard,
        TokenContract,
        SendSubAsset,
        Exchange,
        ConfirmLogout
    },
    data() {
        return {
            sideBar: false,
            modalState: 'main',
            state: 'main-wallet',
            selectedAsset: {},
            noAsset: true,
            ifAddingAsset: false,
            isNft: false,
            symbol: "",
            assetInfo: {},
            subwalletInfo: {},
            showAsset: false,
            status: "UNCONFIRMED",
            showLoading: false,
            cbtFund: {},
            daoFund: {}
        }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
        formatAmount:  function (num) {
            if(isNaN(num)) {
            num = 0;
            }
            return parseFloat(num).toFixed(8)
        },
        formatDate: function(time){
            return new Date(time).toLocaleString();
        },
        openSidebar() {
            this.sideBar = !this.sideBar
        },
        privateKey() {
            this.modalState = 'privateKey'
        },
        changePin() {
            this.$bvModal.show('change-pin-modal'); 
            this.modalState = 'changePin'
        },
        exitModal() {
            this.modalState = 'main'
        },
        viewPrivateKey() {
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal');
        },
        importPrivateKey() {
            this.$bvModal.show('import-key-modal'); 
        },
        changeState(state) {
            this.state = state
        },
        profile() {
            this.$router.push('/profile')
        },
        transactions() {
            this.$router.push('/transactions')
        },
        initContract: function(type) {
            this.ifAddingAsset = true
            if(type == 'token') {
                this.isNft = false
            } else if(type == 'nft') {
                this.isNft = true
            }
            console.log(this.isNft)
        },
        closeContract() {
            this.ifAddingAsset = false
            this.noAsset = false
        },
        send: function() {
            this.$bvModal.show('send-modal'); 
        },
        sendAsset: function(info) {
            this.$bvModal.show('send-asset-modal'); 
            this.selectedAsset = info
        },
        receive: function() {
            this.$bvModal.show('deposit-modal'); 
        },
        exchange: function(){
            this.$bvModal.show('exchange-modal'); 
        },
        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },
        viewSubCard(selected, ifNft) {
            if(ifNft == false) {
                this.$router.push({ name: 'SubAssetDashboard', params: {token: selected}})
            } else if (ifNft == true) {
                this.$router.push({ name: 'SubNftDashboard', params: {nft: selected}})
            } else {
                console.log(ifNft)
                console.log(selected)
            }
        },
        checkSession: function() {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/sessioncheck',
            };

            this.axios(options)
            .then((response) => { 
                this.getProfile();
                }).catch((err) => {
                    
                if(err.response.data.msgCode == '00001') {
                    window.location.href = "/#/login";
                }  

                if(err.response.data.msgCode == '00020') {

                    setTimeout( () => {
                    window.location.href = "/#/setup-profile";
                },1000)
                    
                }  

                })
        },
        getAssetInfo() {
            const options = {
                method: 'POST',
                url: '/cx/smartcontract',
            };
            this.axios(options)
                .then((response) => {
                    if(response.data.length > 0) {
                        
                        this.noAsset = false 
                        this.assetInfo = response.data;
                        // setTimeout(()=>{
                        //   console.log('yes1111')
                        //   console.log(this.subwalletInfo)
                        //   //this.assetInfo = response.data

                        //   this.assetInfo = [];

                        //   response.data.forEach((el,i) => {
                        //     this.subwalletInfo.forEach((el2,i2) => {
                        //       if(el.symbol == el2.symbol & el2.balance >  0) {
                        //         this.assetInfo.push(el);
                        //       }
                        //     })
                        //   })

                        

                        // },500)
                    } else {
                    console.log('no')
                    // this.initSelect()
                    }
                })
                .catch((response) => {
                    console.log(response.response)
                    // if(response.assetKey != "" && response.assetName == "") {
                    //   this.showSteps.one = false
                    //   this.showCheck.one = true
                    //   this.showSteps.two = false
                    //   this.showSteps.three = true
                    //   this.showCheck.two = true
                    //   this.isDisabled.three = false
                    // }
                });
        },

        getSubwalletInfo() {
            const options = {
                method: 'GET',
                url: '/pub/wallets/' + this.init.address.addressValue,
            };
            this.axios(options)
                .then((response) => {
                console.log(response.data)
                if(response.data.length > 0) {
                    console.log('yes')
                    this.subwalletInfo = response.data
                    console.log(this.subwalletInfo)
                } else {
                    console.log('no')
                    // this.initSelect()
                }
                })
                .catch((error) => {
                console.log(error)
                // if(response.assetKey != "" && response.assetName == "") {
                //   this.showSteps.one = false
                //   this.showCheck.one = true
                //   this.showSteps.two = false
                //   this.showSteps.three = true
                //   this.showCheck.two = true
                //   this.isDisabled.three = false
                // }
                });
        },

        getAddress: function(){
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/address/CEZA',
            };

            this.axios(options)
                .then((response) => {
                    this.init.address = response.data;
                    //console.log(this.init.address)
                    this.getFund();
                    this.getSubwalletInfo();
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getFund: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/CEZA/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                
                this.init.fund = response.data;
                this.init.fund.history.forEach((value, index) => {
                    if(value.confirmations >= 2) {
                        console.log(value.confirmations)
                        this.status = "COMPLETED"
                    } else {
                        console.log('error')
                        this.status = "PENDING"
                    }

                });

                // console.log(this.init.fund.balance);

                this.init.fund.history.sort(function(a, b) {

                    return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
                });

                this.init.fund.history = this.init.fund.history.slice(0, 10);

                this.getCbtFund();
                this.getDaoFund();
                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getCbtFund: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/CBT/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                
                this.cbtFund = response.data;

                // console.log(this.init.fund.balance);

                setTimeout( () => {
                    this.showLoading = false;
                },1000)

                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },

        getDaoFund: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                //url: '/blx-api/address/BLX/' + this.init.address.addressValue,
                url: '/blx-api/address/DAO/' + this.init.address.addressValue,
            };

            this.axios(options)
                .then((response) => {
                
                this.daoFund = response.data;

                // console.log(this.init.fund.balance);

                setTimeout( () => {
                    this.showLoading = false;
                },1000)

                }).catch((err) => {
                    
                    console.log(err.response.data)     

                    
                })
        },


        getProfile: function(){
            const options = {
                method: 'GET',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                url: '/cx/profile/',
            };

            this.axios(options)
                .then((response) => {
                
                this.init.profile = response.data;
    


                    
                })
        },
    },
    mounted() {
        this.checkSession();
        this.getAddress();
        this.getAssetInfo();
        let socketScript = document.createElement('script')
            socketScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js')
            document.head.appendChild(socketScript)
        console.log(this.init.fund.history.confirmations)
    }
}
</script>

<style scoped lang="scss">
    .wrapper {
        position:relative;
        .sidebar {
            position:fixed;
            padding:20px;
            width:20em;
            background-color:#000;
            height:100vh;
            z-index:2;
            .sidebar-link {
                padding:20px;
                cursor:pointer;
                &.active {
                    background-image: linear-gradient(to right, rgba(202,0,1,1), rgba(188,0,2,0.5), rgba(170,0,0,0.1));
                    border-radius:5px;
                }
                .sidebar-link-img {
                    width:20px;
                    object-fit:contain;
                    height:auto;
                    margin-right:4px;
                }
                .sidebar-link-text {
                    color:white;
                    margin-left:4px;
                    margin-top:13px;
                }
                .arrow-down {
                    width:15px;
                    object-fit:contain;
                    height:auto;
                }
            }
        }
        .key-choices {
            position: absolute;
            background: #fff;
            color: #333;
            top: 100px;
            overflow: auto;
            z-index: 3;
            width:100%;
            border-radius:10px;
            .choice {
                width:100%;
                padding:10px;
                &:hover {
                    background-color:blue;
                    color:white;
                }
            }
        }
        .main-body {
            width:100%;
            padding:50px 70px;
            .sidebar-toggler {
                background:none;
                width:2.5em;
                height:2.5em;
                border:#fff 1px solid;
                border-radius:5px;
                fill:white;
            }
            .search-bar {
                width:20em;
            }
            .left-section {
                width:35em;
                .topbar-img {
                    object-fit: contain;
                }
                .profile {
                    .profile-name {
                        color:white;
                        margin-bottom:0;
                        font-family: 'SSN-Bold';
                    }
                    .profile-email {
                        color:white;
                        font-family: 'SSN-Light';
                        margin-bottom:0;
                    }
                }
            }
            .tabs {
                width: 50em;
                .tab {
                    cursor:pointer;
                    color:#fff;
                    font-family:SSN-Roman;
                    &.active {
                        color:#0567d7;
                    }
                    h6 {
                        margin-bottom:2px;
                    }
                }
                .active-circle {
                    width:10px;
                    height:10px;
                    background: #0567d7;
                    border-radius:50%;
                }
            }
            .main-wallet {
                width:100%;
                .ceza-wallet {
                    color:white;
                    background-image: url('../assets/ceza wallet bg.png');
                    background-size:cover;
                    background-repeat: no-repeat;
                    padding:30px;
                    height:18em;
                    position:relative;
                    width:75%;
                    .address {
                        font-family:SSN-Bold;
                    }
                    .balance-label {
                        font-family:SSN-Roman;
                        color:#cecece;
                        margin-top:2em;
                        margin-bottom:0;
                    }
                    .balance {
                        font-family:SSN-Bold;
                    }
                    .wallet-button {
                        opacity:80%;
                        transition:.4s;
                        &:hover {
                            opacity:100%;
                        }
                    }
                    .ceza-img {
                        position:absolute;
                        left:100%;
                    }
                }
                .other-wallet {
                    width:100%;
                    .cbt-wallet {
                        width:37em;
                        height:16.5em;
                        background-image: url('../assets/cbt bg.png');
                        background-size:cover;
                        background-repeat: no-repeat;
                        padding:20px;
                    }
                    .dao-wallet {
                        width:37em;
                        background-image: url('../assets/dao bg.png');
                        background-size:cover;
                        background-repeat: no-repeat;
                        padding:20px;
                    }
                    .wallet-info {
                        width:25em;
                        .address {
                            font-family:SSN-Roman;
                            color:white;
                            font-size:18px;
                        }
                        .balance-label {
                            font-family:SSN-Roman;
                            color:#cecece;
                            margin-top:2em;
                            margin-bottom:0;
                        }
                        .balance {
                            font-family:SSN-Bold;
                            color:white;
                        }
                    }
                    .wallet-img {
                        width:8em;
                    }
                }
                .exchange {
                    width:100%;
                    padding:20px;
                    background-image: linear-gradient(to right, #110737, #150754);
                    border-radius:5px;
                    .form {
                        .input-group {
                            width:36em;
                        }
                        .btn-main {
                            width:70em;
                        }
                    }
                }
                .history {
                    width:100%;
                    padding:30px;
                    background-image: linear-gradient(to right, #110737, #150754);
                    border-radius:5px;
                    .transactions {
                        width:30em;
                        .transaction {
                            background: rgba(125, 125, 125, 0.5);
                            padding:15px;
                            border-radius:10px;
                            .dot {
                                object-fit:contain;
                                width:15px;
                                height:15px;
                            }
                            .transaction-info {
                                font-family:SSN-Roman;
                                .hash {
                                    white-space: nowrap !important;
                                    width: 20em;
                                    overflow: hidden !important;
                                    text-overflow: ellipsis !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>