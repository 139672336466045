<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
            <h5 class="mt-2">{{ $t('createAsset.uploadNftLabel') }}</h5>
            <p @click="closeModal" class="x" v-if="showX">x</p>
        </div>
      <div class="modalBody">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
          <div>
            <h5>{{ $t('createAsset.maximum') }}</h5>
          </div>
            <div class="buttonWrapper">
                <div class="dropbox">
                    <input type="file" multiple :name="uploadFieldName" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                      accept="image/*, audio/*, video/*, application/pdf" class="submit mt-3">
                  </div>
            </div>
        </form>
        <div v-if="isSuccess">
            <h2 v-if="lang == 'en'">Uploaded {{ uploadedFiles.length }} file(s) successfully.</h2>
            <h2 v-if="lang == 'kr'">파일 {{ uploadedFiles.length }}개를 업로드했습니다.</h2>
            <p>
              <button class="submit" @click="done()">Done</button>
            </p>
            <ul class="list-unstyled">
              <li v-for="item in uploadedFiles">
                <img :src="item.url" class="img-responsive img-thumbnail" :alt="item.originalName">
              </li>
            </ul>
          </div>
          <!--FAILED-->
          <div v-if="isFailed">
            <h2>Uploaded failed.</h2>
            <h5 style="color:red;">{{ uploadError }}</h5>
            <p>
              <a href="javascript:void(0)" @click="reset()">Try again</a>
            </p>
          </div>
          <div class="text-center">
            <img class="iconImg" :src="imgUrl" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from '../../store/nft-upload.service';
 const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
    props:["symbol"],
    components: {

    },
    data: function() {
        return {
            uploadedFiles: [],
            uploadError: null,
            currentStatus: null,
            uploadFieldName: 'mediaFile',
            imgUrl: "",
             showCheck: {
              one: false,
              two: false,
              three: false,
              four: false,
            },
             showSteps: {
          one: true,
          two: true,
          three: true,
          four: true,

        },

        isDisabled: {
          two: true,
          three: true,
          four: true,
        },

        showX: true,
        }
    },

    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },
      lang() {
			return localStorage.getItem('lang');
		}
    },

    mounted() {
     
    },

    watch: {
        // 'signupData.loginId': function(newValue, oldValue) {
        //     if (newValue != oldValue) {
        //         this.errorMsg = ''
        //     }
        // },
        // 'signupData.loginPass': function(newValue, oldValue) {
        //     if (newValue != oldValue) {
        //         this.errorMsg = ''
        //     }
        // },
    },

    methods: {
        closeModal(){
            this.$emit("close")
        },

        done(){

           
          this.$emit("close")
            this.$emit("done")
        },

        reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.uploadError = null;
      },
      save(formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;
        console.log("./.")
        upload(formData)
          .then(x => {
            this.uploadedFiles = [].concat(x);
            console.log(x)
            this.currentStatus = STATUS_SUCCESS;
            this.showX = false,
            console.log(this.currentStatus)
            this.axios.post('/cx/smartcontract')
                .then((response) => { 
                
                this.imgUrl = response.data.icon
                }).catch((err) => { 
                console.log(err)

                })
          })
          .catch(err => {
              console.log(err.response)
            this.uploadError = err.response.data.fieldErrors[0].defaultMessage;
            this.currentStatus = STATUS_FAILED;
          });
      },
      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();

        if (!fileList.length) return;

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
            formData.append('symbol', this.symbol);
          });

        // save it
        this.save(formData);
      }

        // uploadImg() {
        //     const params = {
        //         assetKey: this.hashKey
        //         // captcha: this.captcha
        //         }

        //         const data = Object.keys(params)
        //         .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        //         .join('&');
        

        //         const options = {
        //         method: 'POST',
        //         headers: { 'content-type': 'application/x-www-form-urlencoded' },
        //         data,
        //         url: '/cx/smartcontract/activate',
        //         };
        //         this.axios(options)
        //             .then((response) => {
        //               this.showSteps.four = false
        //               this.showCheck.four = true
        //             })
        //             .catch((error) => {
        //               this.errors.noHash = "Invalid asset key."
        //             });
        // },
    },

    mounted() {
      this.reset();
    },
}
</script>

<style scoped>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    background: rgba(0, 0, 0, 0.6);
}
.b-modal {
    width: 620px;
    height: auto;
    margin: 175px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 2;
    overflow-x: hidden !important;
    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:black;
}

.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.submit {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color:orange;
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:100%;
    margin-right:20px;
}

.ok {
    background-color:rgb(255, 102, 0);
    color:white;
    font-family:'PoppinsMedium', sans-serif;
    width:175px;
    margin-right:20px;
}

.cancel {
    color:#191919;
    background-color:lightgray;
    font-family:'PoppinsMedium', sans-serif;
    width:100%;

}

.iconImg {
    width:50%;
}


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
</style>