<template>
	<b-modal id="exchange-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
		
				<div>
					<v-icon name="chevron-left" @click="close()" class="icon"/>
				</div>
				<h5>
					EXCHANGE CEZA
				</h5> 
				<div>
				</div>

		</template>
		<div class="text-center" v-if="state == '1'">
			<div class="d-flex justify-content-center text-center">
					<div class="wrapper-one mx-auto">
						<div class="input-group mx-auto">
							<div class="input-group-prepend">
								<div class="btn btn-toggler mt-1">
								<img class="default-token" :src="require('../../assets/tokens/CEZA.png' )">
								</div>
							</div>
						</div>
					</div>
					<div class="swap-arrow-wrapper">
						<img src="../../assets/swap arrow.png" alt="" class="swap-arrow">
					</div>
					<div class="wrapper-two mx-auto">
						<div class="input-group mx-auto">
							<div class="input-group-prepend">
								<button class="btn btn-toggler" type="button" v-on:click="toggleCountryListTwo">
								<img class="default-token" :src="require('../../assets/tokens/' + currency.two + '.png' )">
								<img src="../../assets/dropdown-white.png" alt="" class="toggler-arrow">
								</button>
							</div>
						</div>
						<div class="currency-list" v-if="showCurrencyListTwo">
						<div class="row">
							<div class="col-sm-12" v-for="c in currencyList" :key="c.id" v-on:click="selectSecondCurrency(c)">
							<div>
								<img class="currency-list-img" :src="require('../../assets/tokens/' + c.currency + '.png' )">
							</div>
							<div class="currency-name"><span> {{ c.currency }}</span></div>
							</div> 
						</div>
					</div>
				</div>
			</div>
			<button class="btn btn-main mt-5 mx-auto" @click="nextStep">Next</button>
		</div>
    <div class="text-center" v-if="state == '2'">
      <div class="text-center mb-3"> <span class="bold"> BALANCE: </span> {{ formatAmount(init.fund.balance) }}  </div>
       <div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<form action="" class="form-group">
        <label for="">Enter amount of CEZA:</label>
        <input type="text" class="form-control" @keyup="getRate" v-model="amount">
        <p class="error">{{ error }}</p>
        <label for="" class="mt-3">Amount of {{currency.two}}:</label>
        <input type="text" class="form-control" v-model="convertedAmount" readonly>
      </form>
			<button class="btn btn-main mt-5 mx-auto" @click="onExchange" :disabled="isDisabled">Exchange</button>
		</div>
    <div v-if="state == 'pin'"> 
		<form  v-on:submit.prevent="verifyPin" class="text-center">
			<p class="text-center">Enter your PIN:</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="pin-wrapper" >
					<div>
					  <input id="pin1" type="password" autocomplete="off" maxlength="1" v-model="pinArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin2"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin3" type="password" autocomplete="off" maxlength="1" v-model="pinArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
					</div>
		  
					<div>
					  <input id="pin4"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
					</div>

					<div>
						<input id="pin5" type="password" autocomplete="off" maxlength="1" v-model="pinArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
					  </div>
			
					  <div>
						<input id="pin6"  type="password" autocomplete="off" maxlength="1" v-model="pinArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
					  </div>
		  
				  </div> 
			</div>
	
			<div class="form-group ">
				<button type="submit" class="btn btn-main">SUBMIT</button>
			</div>

		</form>
	</div>
	<div v-if="state == 'otp'"> 
		<form  v-on:submit.prevent="verifyOtp">
	
			<p class="text-center">Enter the one-time PIN you received:</p>
			<div class="alert alert-danger text-center" v-if="showError"> {{ systemMsg }} </div>
			<div class="d-flex justify-content-center">
				<div class="otp-wrapper" >
				<div>
					<input id="otp1" type="text" autocomplete="off" maxlength="1" v-model="otpArr[0]" class="otp-input otp-input-100" @keydown="otpBack($event,1)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp2"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[1]" class="otp-input otp-input-100" @keydown="otpBack($event,2)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp3" type="text" autocomplete="off" maxlength="1" v-model="otpArr[2]" class="otp-input otp-input-100" @keydown="otpBack($event,3)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp4"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[3]" class="otp-input otp-input-100" @keydown="otpBack($event,4)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp5"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[4]" class="otp-input otp-input-100" @keydown="otpBack($event,5)" @keypress="isNumber($event)">
				</div>
		
				<div>
					<input id="otp6"  type="text" autocomplete="off" maxlength="1" v-model="otpArr[5]" class="otp-input otp-input-100" @keydown="otpBack($event,6)" @keypress="isNumber($event)">
				</div>
				</div>
			</div>
	<div class="text-center">
	  <p @click="resendCode" v-if="!ifResent" style="cursor:pointer;">Didn't receive 2FA?  <span style="text-decoration: underline;">Try Again</span></p>
	  <p v-if="ifResent">{{ timeFormat(timerInSeconds) }}</p>
			</div>
	
			<div class="form-group text-center">
				<button type="submit" class="btn btn-main" :disabled="isOtpDisabled">SUBMIT</button>
			</div>
		</form>
	</div>
	<div v-if="state == 'success'"> 
		<div class="text-center">
			<h5>
			Transaction Successful. 
			</h5>
      <div class="exchange-details mb-4 text-left">
        <div class="d-flex justify-content-between">
          <h6><span class="highlight">Tokens:</span></h6>
          <h6><img :src="require('../../assets/tokens/CEZA.png')" class="icon">CEZA/<img :src="require('../../assets/tokens/' + currency.two + '.png')" class="icon">{{currency.two}}</h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6><span class="highlight">Amount in {{currency.two}}:</span></h6>
          <h6>{{formatAmount(convertedAmount)}}</h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6><span class="highlight">Price:</span></h6>
          <h6>{{formatAmount(rate)}} per {{currency.two}}/CEZA</h6>
        </div>
      </div>
			<button class="btn btn-main" @click="closeReload">DONE</button>
		</div>
	</div>
    </b-modal>
</template>

<script>
import numeral from 'numeral' 
export default {
	data() {
		return {
      state: '1',
			currencyList: [
				{ currency: 'BLX' },
			],
			filteredCurrencyList: [],
			currency: {
				one: 'CEZA',
				two: 'BLX'
			},
			values: {
				one: '',
				two: ''
			},
			hideCurrency: false,
			showCurrencyListOne: false,
			showCurrencyListTwo: false,
			hideToken: false,
      amount: "",
      error: "",
      convertedAmount: "",
      showError: false,
      systemMsg: "",
      isDisabled: false,
      isOtpDisabled: false,
      ifResent: true,
      timerInSeconds: 60,
      pinArr: [],
      otpArr: [],
      txnControlId: "",
      rate: "",
		}
	},
  computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
    methods: {
      nextStep() {
        this.state = '2'
      },

      closeReload: function(){
			
			window.location.reload();
		},

    formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },

      getRate() {
        var toCurrency = "";
        if(this.currency.two == 'BTC') {
          toCurrency = 'XBTC'
        } else if(this.currency.two == 'ETH') {
          toCurrency = 'XETH'
        } else {
          toCurrency = this.currency.two
        }

        const params = {
					fromCurrency: 'CEZA',
          toCurrency: toCurrency,
          amount: this.amount,
				}

				const data = Object.keys(params)
					.map((key) => `${key}=${encodeURIComponent(params[key])}`)
					.join('&');

			  const options = {
					method: 'POST',
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
					url: '/pub/getxchgrate',
					data,
					};
					this.axios(options)
						.then((response) => {
							console.log(response)
              this.convertedAmount = response.data.convertedAmount
              this.rate = response.data.xchgRate
						})
						.catch((error) => {
						});
      },
		toggleCountryListOne() {
			this.showCurrencyListOne = !this.showCurrencyListOne
			this.showCurrencyListTwo = false
		},
		selectFirstCurrency(selected) {
			this.showCurrencyListOne = false
			this.currency.one = selected.currency
			this.values.one = ''
			this.values.two = ''
			this.isReadOnly.one = false
			this.isReadOnly.two = false
			this.removeSimilarValues();
		},
		toggleCountryListTwo() {
			this.showCurrencyListTwo = !this.showCurrencyListTwo
		},
		selectSecondCurrency(selected) {
      this.showCurrencyListTwo = false
      this.currency.two = selected.currency
      this.values.one = ''
      this.values.two = ''
      this.isReadOnly.one = false
      this.isReadOnly.two = false
      this.filterArray();
    },
    removeSimilarValues() {
      this.currencyList.forEach((value, index) => {
        if(value.currency != this.currency.one) {
          if(value.currency != this.currency.two) {
            return value.currency
          }
        }
      })
    },
    filterArray() {
      this.currencyList.filter(this.removeSimilarValues);
      console.log(this.currencyList)
    },

    timer() {
            let counterInterval = setInterval(() => {
                this.timerInSeconds--;
                // console.log(this.timerInSeconds)

                if(this.timerInSeconds == 0 || this.ifResent == false) {
                    clearInterval(counterInterval)
                    this.ifResent = false
                    this.timerInSeconds = 60
                }
            }, 1000);
        },

        timeFormat(seconds) {
            let date = new Date(0);
            date.setSeconds(seconds);
            return date.toISOString().substr(14, 5);
        },

		verifyPin() {
            const params = {
                pin: this.pinArr[0] + this.pinArr[1] + this.pinArr[2] + this.pinArr[3] + this.pinArr[4] + this.pinArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validatepin',
                };
                this.axios(options)
                    .then((response) => {
						this.state = 'otp'
                      this.clearError();
                      this.timer();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.pinArr = []
						this.showError = true
						this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
						},10000);
					});

        },

		clearError() {
			this.systemMsg = ""
			this.showError = false
		},

		verifyOtp() {
      this.isOtpDisabled = true
			const params = {
                otp: this.otpArr[0] + this.otpArr[1] + this.otpArr[2] + this.otpArr[3] + this.otpArr[4] + this.otpArr[5],
                }

                const data = Object.keys(params)
                .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                .join('&');
        

                const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data,
                url: '/cx/private/validate2fa',
                };
                this.axios(options)
                    .then((response) => {
                     this.confirmExchange();
					          this.clearError();
                    })
                    .catch((error) => {
                        // Error code goes here
                        this.isOtpDisabled = false
						this.showError = true
            			this.otpArr = []
                        this.systemMsg = error.response.data.msgText
						console.log(error.response.data.msgText)
						setTimeout(()=>{
						this.clearError()
					},10000);
				});
        },

		isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      otpBack: function(event, currrentBox) {
		if(this.state == 'pin') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("pin"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("pin"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		} else if(this.state == 'otp') {
			if (event.keyCode === 8) {
              if (currrentBox != 1) {

                  setTimeout(function() { 
                      document.getElementById("otp"+ (currrentBox - 1)).focus();
                  }, 50)

              }
          }

          if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105 || event.keyCode == 229)) {

              setTimeout(function() { 

                  if((currrentBox) < 6) {
                    document.getElementById("otp"+ (currrentBox + 1)).focus();
                  }
                  
              }, 50)

          }
		}
      },

    onExchange() {
      this.isDisabled = true
      let hasError = false;
      if(this.amount == '' || this.amount == undefined || this.amount == null) {
        this.error = 'Please enter an amount.';
        this.isDisabled = false
        hasError = true;
        setTimeout(() => {
        this.error = ""
      }, 5000)
      } else {
        if(this.amount < 0) {
          this.error = 'Amount should be greater than zero.';
          this.isDisabled = false
          hasError = true;
        }
        setTimeout(() => {
        this.error = ""
      }, 5000)
      }

      if(!hasError) {
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/txn/exchange/init',
        };
        this.axios(options)
          .then((response) => {
            console.log(response)
            this.txnControlId = response.data.txnControlId
            var toCurrency = "";
            if(this.currency.two == 'BTC') {
              toCurrency = 'XBTC'
            } else if(this.currency.two == 'ETH') {
              toCurrency = 'XETH'
            } else {
              toCurrency = this.currency.two
            }
            const params = {
              sourceAmount: this.amount,
              sourceCurrency: 'CEZA',
              targetCurrency: toCurrency,
              txnControlId: this.txnControlId,
            }

            const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');

            const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              url: '/cx/txn/exchange/submit',
              data,
              };
              this.axios(options)
                .then((response) => {
                  this.showError = false
                  this.state = 'pin'
                })
                .catch((error) => {
                  this.isDisabled = false
                  this.showError = true
                  if(error.response.data.fieldErrors) {
                    this.systemMsg = error.response.data.fieldErrors[0].defaultMessage
                  } else {
                    this.systemMsg = error.response.data.msgText 
                  }
                });
          })
          .catch((error) => {

          });
        }
	    },

      confirmExchange () {
        const params = {
              txnControlId: this.txnControlId,
            }

            const data = Object.keys(params)
              .map((key) => `${key}=${encodeURIComponent(params[key])}`)
              .join('&');

            const options = {
              method: 'POST',
              headers: { 'content-type': 'application/x-www-form-urlencoded' },
              url: '/cx/txn/exchange/confirm',
              data,
              };
              this.axios(options)
                .then((response) => {
                  this.state = 'success'
                })
                .catch((error) => {
                  // this.isDisabled = false
                  // this.showError = true
                  // this.systemMsg = error.response.data.msgText
                });
      },

      resendCode: function() {
          const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/private/resend2fa',
          };

          this.axios(options)
            .then((response ) => { 
              this.systemMsg = ""
              this.showError = false
              this.ifResent = true
              console.log(this.ifResent)
              this.timer();
            }).catch((err) => {
                console.log('resend code errror')
                console.log(err)
            })
        },

      close: function(){
   			this.$bvModal.hide('exchange-modal'); 
        this.state = '1';
        this.showCurrencyListTwo = false
        this.showError = false;
        this.isDisabled = false
        this.error = "",
        this.convertedAmount = ""
        this.amount = ""
        this.isOtpDisabled = false
   		},
    }
}
</script>

<style scoped lang="scss">

  .main-wrapper {
      margin-top:100px;
      width:500px;
      padding: 80px 5px;
      background-color: #121b25;
      border-radius:5px;
  }

  .btn-toggler {
      background-color:transparent;
      color:black;
  }

  .toggler-arrow {
      margin-left:0.5em;
	  background:linear-gradient(to right, #120441, #220c61);
	padding:8px;
	border-radius:50px;
  }

  .input-group {
      width:30em;
  }

  .wrapper-one {
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    width:100px;
    .input-group-prepend {
    height: 38px;
    .btn-toggler {
        border-radius:5px;
        padding: 5px;
        background-color:transparent;
        height:100px;
        &:focus {
          outline:none;
        }
    }
    }
    .default-token {
        width: 5em;
        margin-right:0.25em;
		background:linear-gradient(to right, #120441, #220c61);
		border-radius:50px;
		padding:2px;
    }
    .currency-list {
    position: absolute;
    background: #ccc;
    color: #333;
    top: 120px;
    padding: 0.5em;
    overflow: auto;
    z-index: 3;
    width:500px;
    .row {
        margin: 0 !important;
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
          &:hover {
              background: darken(#ccc, 10%);
          }
          .currency-name {
              margin-left: 0.5em;
          }
          span {
              font-size: 0.9em;
          }
        }
        .hide-token {
          display:none;
        }
      }
    }
  }

  .wrapper-two {
    position: relative;
    padding-top: 20px;
    padding-bottom: 30px;
    width:100px;
    .input-group-prepend {
    height: 38px;
    .btn-toggler {
        border-radius:5px;
        padding: 5px;
        background-color:transparent;
        height:100px;
    }
    }
    .default-token {
        width: 5em;
        margin-right:0.25em;
		background:linear-gradient(to right, #120441, #220c61);
		border-radius:50px;
		padding:2px;
    }
    .currency-list {
    position: absolute;
    background: #ccc;
    color: #333;
    top: 120px;
    padding: 0.5em;
    overflow: auto;
    z-index: 3;
    width:200px;
    .row {
        margin: 0 !important;
        .col-sm-12 {
        display: flex;
        padding: 0.5em;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: darken(#ccc, 10%);
        }
        .currency-name {
            margin-left: 0.5em;
        }

        span {
            font-size: 0.9em;
        }
        }
      }
    }
  }

  .currency-list-img {
    width: 33px;
  }

  .btn-cancel {
    margin-top:20px;
    color:white;
    border: 1px white solid;
    width: 200px;
  }

  .btn-connect {
    margin-top:20px;
    color:white;
    background-image: linear-gradient(to right, #120441, #220c61);
    width: 200px;
  }

  .swap-arrow-wrapper {
	margin-top:50px;
	background:linear-gradient(to right, #120441, #220c61);
	padding:10px;
	border-radius:50px;
  }

  .swap-arrow {
    width:2em;
    height:2em;
  }

  .min-max-wrapper {
    width: 250px;
    .btn-min-max {
        background-color: white;
        color: black;
    }
  }

  .amount-wrapper {
    width: 400px;
    padding:10px;
    background-color:white;
    border-radius:10px;
    color:black;
    margin: 10px 0px;
    .form-wrapper {
        margin-left:3em;
        .form-control {
            border: 1px black solid;
        }
    }
    .bec-token {
        border:1px black solid;
        border-radius:25px;
    }
  }

  .error-wrapper {
      width: 400px;
  }

  .image-stack-wrapper {
        background-color:transparent;
        .image-stack {
            display: grid;
            position: relative; // imperative for the overlapping to work
            grid-template-columns: repeat(12, 1fr);
            width:80px;
            .image-stack__item--top {
                grid-column: 4 / -1;
                grid-column: 1 / span 8;
                grid-row: 1;
            }

            .image-stack__item--bottom {
                grid-row: 1;
                grid-column: 4 / -1;
                padding-top: 20%; 
                z-index: 1; // slightly arbitrary, keeps proportion once resized
                // tells the browser to make this image on top
            }

            img {
            width: 40px;
            display: block;
            }
        }
    }

    .image-stack-wrapper {
        background-color:transparent;
        .image-stack {
            display: grid;
            position: relative; // imperative for the overlapping to work
            grid-template-columns: repeat(12, 1fr);
            width:80px;
            .image-stack__item--top {
                grid-column: 4 / -1;
                grid-column: 1 / span 8;
                grid-row: 1;
            }

            .image-stack__item--bottom {
                grid-row: 1;
                grid-column: 4 / -1;
                padding-top: 20%; 
                z-index: 1; // slightly arbitrary, keeps proportion once resized
                // tells the browser to make this image on top
            }

            img {
            width: 40px;
            display: block;
            }
        }
    }

    .group-forms {
      .input-group-prepend {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border:1px #ced4da solid;
      }
      .input-group-append {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border:1px #ced4da solid;
      }
    }

    .error {
      color:red;
    }

    .otp-wrapper, .pin-wrapper {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-bottom: 1em;
      div {
      margin: 0.25em;
	  .otp-input {
              width: 47px;
              text-align: center;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              font-size: 2em; 
              border: 2px solid black;
              background: #FFFFFF;
              color: #262d33;
              border-radius: 6px;
              height: 60px;
              padding-bottom:7.5px;
      }
    }
	}

  .exchange-details {
    border: 1px gray solid;
    padding:10px;
    border-radius:10px;
    width:80%;
    margin:auto;
  }
  .highlight {
    font-weight:bold;
    color:#120441;
  }

  .icon {
    width:2em;
  }
</style>